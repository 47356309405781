import React, { ReactElement, useEffect } from 'react';
import {
  Breadcrumb, Col, Row, Spin,
} from 'antd';
import {
  useNavigate,
  useParams,
} from 'react-router';
import { Link } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import AreaChart from './components/charts/AreaChart';
import PageHeaderContent from './components/PageHeaderContent';
import { useGetLocationByIdQuery } from '../../../services/api';
import styles from './index.module.scss';
import GraphCard from './components/GraphCard';
import { ConnectedKpiCard } from './components/KpiCard';
import LineChart from './components/charts/LineChart';

const { spin } = styles;

function Detail(): ReactElement {
  const navigate = useNavigate();
  const params = useParams();
  const { locationId } = params;

  const {
    data: location,
    isFetching: isLoading,
    isSuccess,
    error,
  } = useGetLocationByIdQuery(locationId || '', { skip: !locationId });

  const breadcrumbItems = [
    <Breadcrumb.Item key="locationList">
      <Link to="/locations/list">Emplazamientos</Link>
    </Breadcrumb.Item>,
    <Breadcrumb.Item key="locationDetail">
      <span>{isSuccess && !isLoading ? location.buildingName : 'Cargando...'}</span>
    </Breadcrumb.Item>,
  ];

  const colStyles = {
    flexBasis: '14%',
    width: '14%',
  };

  useEffect(() => {
    if (!location && error) {
      throw new Response('', {
        status: 404,
        statusText: 'Lo sentimos, pero esta ubicación no existe',
      });
    }
  }, [error, location]);

  if (isLoading) {
    return (
      <div className={spin}>
        <Spin size="large" />
      </div>
    );
  }
  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            onBack={() => navigate(-1)}
            title={location?.buildingName}
            ghost={false}
            breadcrumb={<Breadcrumb>{breadcrumbItems}</Breadcrumb>}
          >
            <PageHeaderContent location={location} />
          </PageHeader>
        </Col>
      </Row>
      <Row align="stretch" justify="space-between" gutter={{ xs: 5, md: 18 }} style={{ marginTop: '18px' }}>
        <Col style={{ ...colStyles }}>
          <ConnectedKpiCard
            status="average"
            title="Consumo teórico mensual"
            units="kW/h"
            type="theoreticalConsumption"
            locationId={locationId}
            showLastDate
          />
        </Col>
        <Col style={{ ...colStyles }}>
          <ConnectedKpiCard
            status="average"
            title="Consumo real mensual"
            units="kW/h"
            type="currentMonthRealConsumption"
            locationId={locationId}
            showLastDate
          />
        </Col>
        <Col style={{ ...colStyles }}>
          <ConnectedKpiCard
            status="average"
            title="Ahorro total mensual"
            units="kW/h"
            type="currentMonthPowerSavingInKw"
            locationId={locationId}
            showLastDate
          />
        </Col>
        <Col style={{ ...colStyles }}>
          <ConnectedKpiCard
            status="average"
            title="Ventiladores"
            type="fanOnOff"
            locationId={locationId}
          />
        </Col>
        <Col style={{ ...colStyles }}>
          <ConnectedKpiCard
            status="average"
            title="Humedad interior"
            units="%"
            type="interiorHumidity"
            locationId={locationId}
          />
        </Col>
        <Col style={{ ...colStyles }}>
          <ConnectedKpiCard
            status="average"
            units="º"
            title="Temperatura interior"
            type="interiorTemperature"
            locationId={locationId}
          />
        </Col>
        <Col style={{ ...colStyles }}>
          <ConnectedKpiCard
            status="average"
            units="º"
            title="Temperatura exterior"
            type="externalTemperature"
            locationId={locationId}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <GraphCard
            title="Consumo de energía"
            type={['instantaneousPowerConsumption']}
            render={({ data: _data, loading }) => (
              <AreaChart
                data={_data}
                loading={loading}
                labels={[
                  { value: 'Consumo de energía', type: 'instantaneousPowerConsumption' },
                ]}
              />
            )}
          />
        </Col>
        <Col span={24}>
          <GraphCard
            title="Ahorro mensual"
            type={['currentMonthRealConsumption', 'theoreticalConsumption']}
            render={({ data: _data, loading }) => (
              <LineChart
                data={_data}
                loading={loading}
                labels={[
                  { value: 'Consumo real mensual', type: 'currentMonthRealConsumption' },
                  { value: 'Consumo teórico mensual', type: 'theoreticalConsumption' },
                ]}
              />
            )}
          />
        </Col>
        <Col span={24}>
          <GraphCard
            title="Temperatura exterior"
            type={['externalTemperature']}
            render={({ data: _data, loading }) => (
              <AreaChart
                data={_data}
                loading={loading}
                minValueZero={false}
              />
            )}
          />
        </Col>
        <Col span={24}>
          <GraphCard
            title="Temperatura interior"
            render={({ data: _data, loading }) => (
              <AreaChart
                data={_data}
                loading={loading}
                minValueZero={false}
              />
            )}
            type={['interiorTemperature']}
          />
        </Col>
        <Col span={24}>
          <GraphCard
            title="Humedad interior"
            render={({ data: _data, loading }) => (
              <AreaChart
                data={_data}
                loading={loading}
              />
            )}
            type={['interiorHumidity']}
          />
        </Col>
        <Col span={24}>
          <GraphCard
            title="Ventilador (apagado/encendido)"
            render={({ data: _data, loading }) => (
              <AreaChart
                data={_data.map((d) => ({ ...d, value: Math.round(d.value) }))}
                loading={loading}
                verticallyCentred={false}
                yAxis={{
                  tickCount: 2,
                  label: {
                    formatter: (text: string) => (text === '0' ? 'Off' : 'On'),
                  },
                }}
              />
            )}
            type={['fanOnOff']}
          />
        </Col>
      </Row>
    </>
  );
}

export default Detail;
