/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Navigate } from 'react-router';
import { Permission } from 'types';
import AuthContext from '../contexts/AuthContext';

interface ProtectedRouteProps {
  component: () => JSX.Element,
  permission: Permission
}

function ProtectedRoute({
  component: Component,
  permission, ...rest
} : ProtectedRouteProps) {
  const { hasPermissions } = useContext(AuthContext);
  return (
    hasPermissions(permission) ? <Component {...rest} /> : <Navigate to="/" />
  );
}

export default ProtectedRoute;
