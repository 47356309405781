import { Alarm } from '../types';

const alarmsData: {label: string, value: Alarm}[] = [
  {
    label: 'Avería de la M1',
    value: 'machine1FailureAlarm',
  },
  {
    label: 'Avería de la M2',
    value: 'machine2FailureAlarm',
  },
  {
    label: 'Avería de la M3',
    value: 'machine3FailureAlarm',
  },
  {
    label: 'Avería de la M4',
    value: 'machine4FailureAlarm',
  },
  {
    label: 'Avería de la M5',
    value: 'machine5FailureAlarm',
  },
  {
    label: 'M1 en manual',
    value: 'machine1InManualRoomAlarm',
  },
  {
    label: 'M2 en manual',
    value: 'machine2InManualRoomAlarm',
  },
  {
    label: 'M3 en manual',
    value: 'machine3InManualRoomAlarm',
  },
  {
    label: 'M4 en manual',
    value: 'machine4InManualRoomAlarm',
  },
  {
    label: 'M5 en manual',
    value: 'machine5InManualRoomAlarm',
  },
  {
    label: 'Ventilador',
    value: 'fanAlarm',
  },
  {
    label: 'Ventilador en manual',
    value: 'fanInManualAlarm',
  },
  {
    label: 'Cambio de filtro',
    value: 'filterChangeAlarm',
  },
  {
    label: 'Temperatura',
    value: 'temperatureAlarm',
  },
  {
    label: 'VPN',
    value: 'VPNConnectionAlarm',
  },
  {
    label: 'Gestor de red',
    value: 'analyzerConnectionFailureAlarm',
  },
  {
    label: 'PLC',
    value: 'PLCConnectionFailureAlarm',
  },
];

export default alarmsData;
