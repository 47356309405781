import { AnyAction, configureStore } from '@reduxjs/toolkit';
import { api } from '../api';
import authReducer from '../auth/slice';

export type AuthState = ReturnType<typeof authReducer>;
export type ApiState = ReturnType<typeof api.reducer>;

export interface RootState {
  auth: AuthState;
  [api.reducerPath]: ApiState;
}

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === 'auth/logOut') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }

  return {
    auth: authReducer(state ? state.auth : undefined, action),
    [api.reducerPath]: api.reducer(state ? state[api.reducerPath] : undefined, action),
  };
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});
