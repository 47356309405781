const paths = {
  login: '/login',
  home: '/',
  locations: {
    list: '/locations/list',
    map: '/locations/map',
    detail: '/locations/detail/:locationId',
  },
  alerts: {
    list: '/alerts/list',
    historics: '/alerts/historics',
  },
};

export default paths;
