import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { DataType, Serie, LatestValue } from '../../types';

export const getHistorics = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<Serie[], {
  type: DataType[],
  location: string,
  month: string,
  year: string
}>({
  queryFn: async ({
    type, location, month, year,
  }, _queryApi, _extraOptions, baseQuery) => {
    const promises = type.map((t: string) => baseQuery(`data/historics?type=${t}&location=${location}&month=${month}&year=${year}`) as MaybePromise<QueryReturnValue<Serie[], unknown, unknown>>);
    return Promise.all(promises)
      .then((res) => ({
        data: res.reduce((acc, cur) => {
          if (Array.isArray(cur.data)) {
            return [...acc, ...cur.data];
          }
          if (cur.data) {
            acc.push(cur.data as Serie);
          }
          return acc;
        // eslint-disable-next-line no-nested-ternary
        }, [] as Serie[]).sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0)),
      })) as MaybePromise<QueryReturnValue<Serie[], unknown, unknown>>;
  },
});

export const getHistoricsLatestValues = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<
LatestValue[], {
  locationId?:string,
  type?:DataType,
}>({
  query: ({ locationId, type }) => `data?type=${type}&location=${locationId}&limit=1&orderByColumn=datetime&orderByAscOrDesc=DESC`,
});
