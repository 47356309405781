import React from 'react';
import { Badge } from 'antd';
import { PresetStatusColorType } from 'antd/es/_util/colors';
import { ConnectionStatus } from 'types';
import styles from './index.module.scss';

const { connection, large } = styles;

interface ConnectionStatusDescriptionProps {
  value: number,
  badgeSize?: 'small' | 'large',
  onlyBadge?: boolean
  onlyDescription?: boolean
}
function ConnectionStatusDescription({
  value,
  badgeSize = 'small',
  onlyBadge = false,
  onlyDescription = false,
} : ConnectionStatusDescriptionProps) {
  const getStatusMessage = (status: ConnectionStatus) => {
    const {
      ConnectionOk, EnergyManagerConnectionError, EnergyManagerReadError,
      PLCConnectionError, PLCEReadError, VPNConnectionError, FilterChangeAlarm,
      MachineFailureAlarm, MachineInManualRoomAlarm, FanAlarm,
    } = ConnectionStatus;
    switch (status) {
      case ConnectionOk:
        return 'Online';
      case EnergyManagerReadError:
        return 'Fallo de lectura del Gestor Energético';
      case PLCEReadError:
        return 'Fallo de lectura del PLC';
      case EnergyManagerConnectionError:
        return 'Fallo de conexión al Gestor Energético';
      case PLCConnectionError:
        return 'Fallo de conexión al PLC';
      case VPNConnectionError:
        return 'Fallo de conexión VPN';
      case FilterChangeAlarm:
        return 'Alarma de cambio de filtro';
      case MachineFailureAlarm:
        return 'Alarma de fallo de máquina';
      case FanAlarm:
        return 'Alarma del ventilador';
      case MachineInManualRoomAlarm:
        return 'Alarma de máquina en manual';

      default:
        return 'Error sin determinar';
    }
  };
  const getStatus = (conexion: ConnectionStatus): PresetStatusColorType => {
    switch (conexion) {
      case ConnectionStatus.ConnectionOk:
        return 'success';
      default:
        return 'error';
    }
  };
  return (
    <div>
      {!onlyDescription && <Badge className={`${connection} ${badgeSize === 'large' ? large : ''}`} status={getStatus(value)} />}
      {!onlyBadge && ` ${getStatusMessage(value)}`}
    </div>
  );
}

export default ConnectionStatusDescription;
