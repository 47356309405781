import React from 'react';
import { Area } from '@ant-design/plots';
import dayjs, { Dayjs } from 'dayjs';
import { DataType, Serie, Chart } from 'types';
import { colors } from '../../../../../../configs';

interface Label {
  type: DataType,
  value: string
}
class DataSerie {
  date: string;

  rawDate: Dayjs;

  value: number;

  type: DataType;

  annotationType?: DataType;

  constructor(serie: Serie) {
    this.rawDate = dayjs.tz(serie.date);
    this.date = this.rawDate.format('DD/MM/YYYY HH:mm');
    this.value = serie.value;
    this.type = serie.type;
    this.annotationType = serie.type;
  }
}

interface AreaChartProps extends Chart {
  labels?: Label[],
  xAxis?: Record<string, unknown>,
  yAxis?: Record<string, unknown>,
}

function AreaChart({
  loading, data, verticallyCentred = true, labels, xAxis, yAxis, minValueZero = true,
} : AreaChartProps) {
  const values = data.map((d) => d.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);

  const getMinValue = () => {
    if (minValueZero) {
      return verticallyCentred && minValue - 10 > 0 ? minValue - 10 : 0;
    }
    return verticallyCentred ? minValue - 10 : minValue;
  };

  return (
    <Area
      loading={loading}
      data={data?.map((d) => new DataSerie(d))
        .sort((a, b) => a.rawDate.diff(b.rawDate)) || []}
      xField="date"
      yField="value"
      color={colors.charts.primaryColor}
      xAxis={{
        grid: {
          line: { type: 'solid', style: { stroke: colors.charts.axisColor } },
        },
        tickCount: 5,
        label: {
          formatter: (text) => dayjs.tz(text, 'DD/MM/YYYY HH:mm', 'Europe/Madrid').format('DD/MM/YYYY'),
        },
        ...xAxis,
      }}
      yAxis={{
        grid: {
          line: { type: 'solid', style: { stroke: colors.charts.axisColor } },
        },
        tickCount: 4,
        min: getMinValue(),
        max: verticallyCentred ? maxValue + 10 : undefined,
        ...yAxis,
      }}
      smooth
      areaStyle={() => ({
        fill: `l(270) 0:#ffffff 0.5:${colors.charts.primaryColorGradient} 1:${colors.charts.axisColor}`,
      })}
      tooltip={{
        formatter: (datum) => ({
          name: labels?.find(({ type }) => type === datum.type)?.value ?? (datum.type || 'Valor'),
          value: datum.value.toLocaleString('es', { maximumFractionDigits: 2 }),
        }),
      }}
      legend={{
        position: 'top',
        itemName: {
          formatter: (text) => labels?.find(({ type }) => type === text)?.value ?? text,
        },
      }}
      animation={{
        appear: {
          animation: 'wave-in',
          duration: 3000,
        },
      }}
    />
  );
}

export default AreaChart;
