import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { Location } from '../../types';

interface Page<T> {
  data: T[],
  total: number
}
export interface QueryParams {
  page?: number,
  limit?: number,
  orderByColumn?: string,
  orderByAscOrDesc?: string,
  populate?: boolean,
  name?:string,
  activeAlarms?:boolean
}

const parseQueryParams = (params: QueryParams) => {
  let parsedParams = '';
  const keys = Object.keys(params);

  if (keys.length) {
    parsedParams += '?';

    keys.forEach((k) => {
      if (k) {
        parsedParams += `${k}=${params[k as keyof QueryParams]?.toString()}&`;
      }
    });
  }
  return parsedParams;
};

export const getLocations = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<Page<Location>, QueryParams>({
  keepUnusedDataFor: 30,
  query: (params) => `/locations${parseQueryParams(params)}`,
  transformResponse: (response: Location[], meta: {response: Response}) => {
    const total = meta.response.headers.get('X-Total-Count');
    return ({ data: response, total: total ? parseInt(total, 10) : 0 });
  },
  providesTags: (result) => (result
    ? [
      ...result.data.map(({ id }) => ({ type: 'Locations', id: id.toString() } as const)),
      { type: 'Locations', id: 'LIST' },
    ]
    : [{ type: 'Locations', id: 'LIST' }]),
});

export const getLocationsActiveAlarms = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<{counter: number | string}, void>({
  query: () => '/locations?activeAlarms=true',
  transformResponse: (response: Location[], meta: {response: Response}) => {
    const total = meta.response.headers.get('X-Total-Count');
    return ({ counter: total || 0 });
  },
  providesTags: ['Counter'],
});

export const getLocationById = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<Location, string>({
  query: (locationId) => `/locations/${locationId}`,
  providesTags: (result) => (result
    ? [
      { type: 'Locations', id: result.id.toString() } as const,
      { type: 'Locations', id: 'LIST' },
    ]
    : [{ type: 'Locations', id: 'LIST' }]),
});
