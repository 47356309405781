import React from 'react';
import Pill from 'components/Pill';
import { Alarm, ConnectionStatus, Location } from 'types';
import { CheckOutlined, CloseOutlined, MinusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import alarmsData from '../../../../../../../configs/alarms';

const {
  wrapper, innerTable, column, columnRow,
} = styles;

interface StatusTableProps {
  location: Location
}

interface Status {
  ip: string,
  code: ConnectionStatus
}

type DeviceType = 'EnergyManager' | 'PLC';

function StatusTable({ location } : StatusTableProps) {
  const status = location.devices?.reduce<{ [index: string]: Status }>((acc, cur)
  : Record<DeviceType | string, Status> | Record<string, never> => ({
    ...acc,
    [cur.type]: { ip: cur.ip, code: cur.connectionStatus },
  }), {});

  const onPillClick = (type: DeviceType) : void => {
    const anchor = document.createElement('a');
    anchor.href = `http://${status[type].ip ?? process.env[`REACT_APP_${type.toUpperCase()}_DEFAULT_IP`]}`;
    anchor.target = '_blank';
    anchor.click();
  };

  const OkIcon = <CheckOutlined style={{ color: '#52c41a' }} />;
  const ErrorIcon = <CloseOutlined style={{ color: '#ff4d4f' }} />;
  const NoDataIcon = <MinusOutlined style={{ color: '#999999' }} />;

  const getIcon = (value: Alarm) => {
    if (typeof location.alarmsState?.[value] !== 'boolean') {
      return NoDataIcon;
    }
    return location.alarmsState?.[value] ? ErrorIcon : OkIcon;
  };

  const firstAlarms = () => {
    const alarms = [...alarmsData];
    return alarms.slice(0, alarmsData.length - 2);
  };

  const lastAlarms = () => {
    const alarms = [...alarmsData];
    return alarms.slice(alarms.length - 2, alarms.length);
  };

  const getFirstAlarmsGrouped = (alarms: {
    label: string;
    value: Alarm;
  }[]) => {
    const validAlarms = alarms.filter((d) => typeof location.alarmsState?.[d.value] === 'boolean');

    const groupedItems = [];
    for (let i = 0; i < validAlarms.length; i += 2) {
      groupedItems.push(validAlarms.slice(i, i + 2));
    }

    return groupedItems.map((group, index) => {
      const items = group.map((d) => (
        <div key={d.label} className={columnRow}>
          <span>{d.label}</span>
          <span>{getIcon(d.value)}</span>
        </div>
      ));

      // eslint-disable-next-line react/no-array-index-key
      return <div className={column} key={index}>{items}</div>;
    });
  };

  return (
    <div className={wrapper}>
      <div className={innerTable}>
        {getFirstAlarmsGrouped(firstAlarms())}
      </div>
      <div className={innerTable}>
        {lastAlarms().map((d, i) => (
          <div key={d.label}>
            <span>{d.label}</span>
            <span style={{
              display: 'flex', alignItems: 'center', marginBottom: '7px',
            }}
            >
              {getIcon(d.value)}
              <span>&nbsp;</span>
              <Pill
                onClick={() => onPillClick(!i ? 'EnergyManager' : 'PLC')}
                status="inactive"
              >
                Ir al cliente web
              </Pill>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StatusTable;
