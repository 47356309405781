/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Card, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const {
  titleWrapper,
  extraWrapper,
  wrapper,
  ghostWrapper,
  breadcrumbWrapper,
  goBackArrow,
} = styles;

interface PageHeaderProps {
  onBack?: () => void,
  children?: JSX.Element,
  title?: string,
  breadcrumb?: JSX.Element,
  extra?: JSX.Element[],
  ghost?: boolean,
  refetchButton?: JSX.Element,
}

function InnerContent({
  breadcrumb, extra, children, title, onBack, ghost, refetchButton,
}: PageHeaderProps) {
  return (
    <div className={`${wrapper} ${ghost ? ghostWrapper : ''}`}>
      <div>
        {breadcrumb && (
          <div className={breadcrumbWrapper}>
            {breadcrumb}
          </div>
        )}
        <div className={titleWrapper}>
          {onBack && <ArrowLeftOutlined className={goBackArrow} onClick={onBack} />}
          <Typography.Title level={4}>{title}</Typography.Title>
          {' '}
          {refetchButton}
        </div>
      </div>
      <div>
        {extra && <div className={extraWrapper}>{extra}</div>}
        {children}
      </div>
    </div>
  );
}

function PageHeader({ ghost = true, ...props }: PageHeaderProps) {
  return (
    ghost ? <InnerContent {...props} ghost={ghost} />
      : <Card><InnerContent {...props} ghost={ghost} /></Card>
  );
}

export default PageHeader;
