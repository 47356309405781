import { Spin } from 'antd';
import React from 'react';
import {
  MapContainer, TileLayer, Marker,
} from 'react-leaflet';
import L from 'leaflet';
import { useOutletContext } from 'react-router';
import marker from 'assets/icons/sync.svg';
import Styles from './index.module.scss';
import LocationPopup from './components/LocationPopup';
import { LocationsOutletContext } from '../types';

const { map, markerWrapper } = Styles;

const customMarker = new L.Icon({
  iconUrl: marker,
  iconRetinaUrl: marker,
  popupAnchor: [-0, -0],
  iconSize: [40, 40],
  className: markerWrapper,
});

function Map() {
  const { locations, isFetching } = useOutletContext<LocationsOutletContext>();
  return (
    isFetching ? <Spin /> : (
      <MapContainer
        className={map}
        center={[40.421751378128214, -3.7042558888624133]}
        zoom={10}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
          url={`https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=${process.env.REACT_APP_STADIAMAPS_API_KEY}`}
        />
        {locations?.map((location) => {
          const { latitude, longitude, id } = location;
          return (
            <Marker
              key={id}
              position={[parseFloat(longitude), parseFloat(latitude)]}
              icon={customMarker}
            >
              <LocationPopup location={location} />
            </Marker>
          );
        })}

      </MapContainer>
    )
  );
}

export default Map;
