import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import { Badge, InputRef } from 'antd';
import SearchFilterIcon from 'components/SearchFilterIcon/intex';
import SearchFilterDropdown from 'components/SearchFilterDropdown';
import ConnectionStatusDescription from 'components/ConnectionStatusDescription';
import { LocationRow } from '../types';
import { AlarmsState } from '../../../types';

const countErrors = (obj?: AlarmsState) => {
  if (!obj) return 0;
  return Object
    .keys(obj)
    .reduce((count, key) => count + (obj[key as keyof AlarmsState] === true ? 1 : 0), 0);
};

function AlarmBadge({ alarms } : {alarms: AlarmsState}) {
  const trueCount = countErrors(alarms);
  const badgeColor = trueCount === 0 ? '#52c41a' : '#ff4d4f';

  return (
    <Badge count={trueCount} style={{ backgroundColor: badgeColor }} showZero />
  );
}

const columns = (inputRef: React.RefObject<InputRef> | undefined) : ColumnsType<LocationRow> => [
  {
    title: 'Nombre',
    dataIndex: 'buildingName',
    key: 'buildingName',
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, close,
    }) => SearchFilterDropdown({
      setSelectedKeys,
      title: 'nombre',
      selectedKeys,
      confirm,
      close,
      inputRef,
    }),
    filterIcon: (filtered: boolean) => SearchFilterIcon({ filtered }),
    onFilter: (
      value: string | number | boolean,
      record: LocationRow,
    ) => record.rawObject.buildingName
      .toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => inputRef?.current?.select(), 100);
      }
    },
    sorter: (
      a: LocationRow,
      b: LocationRow,
    ) => a.rawObject.buildingName.localeCompare(b.rawObject.buildingName),
  },
  {
    title: 'Ahorro Energético',
    dataIndex: 'currentMonthPowerSavingInPercentage',
    key: 'currentMonthPowerSavingInPercentage',
    width: '30%',
    sorter: (a: LocationRow, b: LocationRow) => (
      // eslint-disable-next-line max-len
      a.rawObject.currentMonthPowerSavingInPercentage - b.rawObject.currentMonthPowerSavingInPercentage
    ),
  },
  {
    title: 'Conexión',
    dataIndex: 'connectionStatus',
    key: 'connectionStatus',
    width: '20%',
    render(value) {
      return <ConnectionStatusDescription value={value} />;
    },
    sorter: (a, b) => a.connectionStatus - b.connectionStatus,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Alarmas',
    dataIndex: 'alarmsState',
    key: 'alarmsState',
    width: '20%',
    render(value) {
      return <AlarmBadge alarms={value} />;
    },
    sorter: (a, b) => countErrors(a.alarmsState) - countErrors(b.alarmsState),
    sortDirections: ['descend', 'ascend'],
  },
];
export default columns;
