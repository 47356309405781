import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';

// eslint-disable-next-line import/prefer-default-export
export const recogniseAlert = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.mutation({
  query: (alertId: string) => ({
    url: `/alerts/${alertId}`,
    method: 'PATCH',
    body: { recognised: true },
  }),
  invalidatesTags: (result, error, id) => [{ type: 'Alerts', id }],
});
