import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { LocationParams } from '../../types';

export const createLocationParams = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.mutation<
LocationParams, Omit<LocationParams, 'id'>>({
  query: (locationParams) => ({
    url: '/location-params',
    method: 'POST',
    body: locationParams,
  }),
  invalidatesTags: ['Locations'],
});

export const getLocationParamsById = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<LocationParams, string>({
  query: (paramsId) => `/location-params/${paramsId}`,
});

export const updateLocationParams = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.mutation<
LocationParams, { id: string, locationParams: Omit<LocationParams, 'id'> }>({
  query: ({ id, locationParams }) => ({
    url: `/location-params/${id}`,
    method: 'PATCH',
    body: locationParams,
  }),
  invalidatesTags: (result, error, { locationParams }) => [{ type: 'Locations', id: locationParams.location }],
});

export const deleteLocationParams = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.mutation<
LocationParams, { id: string }>({
  query: (id) => ({
    url: `/location-params/${id}`,
    method: 'DELETE',
    body: {},
  }),
});
