import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Login from 'pages/Login';
import MainLayout from 'layout/MainLayout';
import LocationsList from 'pages/Locations/List';
import LocationsMap from 'pages/Locations/Map';
import Detail from 'pages/Locations/Detail';
import AlertsList from 'pages/Alerts/List';
import HistoricsList from 'pages/Alerts/Historics';
import { Permission } from 'types';
import LocationsSection from 'pages/Locations/Section';
import ErrorPage from 'layout/ErrorPage';
import ProtectedRoute from './protectedRoute';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'locations',
        element: <LocationsSection />,
        children: [
          {
            path: 'list',
            element: <ProtectedRoute
              component={LocationsList}
              permission={Permission.VIEW_LOCATIONS_LIST}
            />,
          },
          {
            path: 'map',
            element: <ProtectedRoute
              component={LocationsMap}
              permission={Permission.VIEW_LOCATIONS_MAP}
            />,
          },
          {
            path: 'detail/:locationId',
            element: <ProtectedRoute
              component={Detail}
              permission={Permission.VIEW_LOCATIONS_DETAILS}
            />,
            children: [
            ],
          },
          {
            path: '',
            element: <Navigate to="/locations/list" replace />,
          },
          {
            path: '*',
            element: <Navigate to="/locations/list" replace />,
          },
        ],
      },
      {
        path: 'alerts',
        children: [
          {
            path: 'list',
            element: <ProtectedRoute
              component={AlertsList}
              permission={Permission.VIEW_ALERTS_LIST}
            />,
          },
          {
            path: 'historics',
            element: <ProtectedRoute
              component={HistoricsList}
              permission={Permission.VIEW_ALERTS_LIST}
            />,
          },
          {
            path: '',
            element: <Navigate to="/alerts/list" replace />,
          },
          {
            path: '*',
            element: <Navigate to="/alerts/list" replace />,
          },
        ],
      },
      {
        path: '',
        element: <Navigate to="/locations/list" replace />,
      },
      {
        path: '*',
        element: <Navigate to="/locations/list" replace />,
      },
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
]);

export default router;
