import React from 'react';
import {
  Button, Col, Form, Modal, Row,
} from 'antd';
import { Location } from 'types';
import { useCreateLocationParamsMutation, useUpdateLocationMutation, useUpdateLocationParamsMutation } from 'services/api';
import InputNumberFormItem from './components/InputNumberFormItem';
import styles from './index.module.scss';
import InputFormItem from './components/InputFormItem';

interface Props {
  isModalOpen: boolean,
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  location: Location,
}

const { formWrapper } = styles;
function ParamsModal({
  isModalOpen,
  setIsModalOpen,
  location,
}: Props): JSX.Element {
  const [form] = Form.useForm();
  const { locationParams, aemetStation } = location;

  const [updateLocationParams] = useUpdateLocationParamsMutation();
  const [createLocationParams] = useCreateLocationParamsMutation();
  const [updateLocation] = useUpdateLocationMutation();

  const handleOk = () => {
    form.validateFields()
      .then(() => {
        const newLocationParams = {
          coefficient0: form.getFieldValue('coefficient0'),
          coefficient1: form.getFieldValue('coefficient1'),
          coefficient2: form.getFieldValue('coefficient2'),
          coefficientA: form.getFieldValue('coefficientA'),
          coefficientB: form.getFieldValue('coefficientB'),
          TI: form.getFieldValue('TI'),
          location: location.id.toString(),
        };
        if (locationParams) {
          updateLocationParams({
            id: locationParams?.id.toString() || '',
            locationParams: newLocationParams,
          });
          updateLocation({
            id: location.id.toString(),
            location: { aemetStation: form.getFieldValue('aemetStation') },
          });
        } else {
          createLocationParams(newLocationParams);
          updateLocation({
            id: location.id.toString(),
            location: { aemetStation: form.getFieldValue('aemetStation') },
          });
        }
        setIsModalOpen(false);
      })
      .catch((err) => console.error(err));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title="Modificar parámetros de referencia"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <Button
          key="link"
          type="primary"
          onClick={handleOk}
        >
          OK
        </Button>,
      ]}
    >
      <Form
        name="modal"
        layout="vertical"
        form={form}
        initialValues={{ ...locationParams, aemetStation }}
        className={formWrapper}
        preserve={false}
      >
        <Row gutter={8}>
          <Col span={12}>
            <InputNumberFormItem placeholder="5,49" label="Parámetro 0" paramName="coefficient0" />
          </Col>
          <Col span={12}>
            <InputNumberFormItem placeholder="8,23" label="Parámetro 1" paramName="coefficient1" />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <InputNumberFormItem placeholder="2,34" label="Parámetro 2" paramName="coefficient2" />
          </Col>
          <Col span={12}>
            <InputNumberFormItem placeholder="4,32" label="Parámetro A" paramName="coefficientA" />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <InputNumberFormItem placeholder="1,23" label="Parámetro B" paramName="coefficientB" />
          </Col>
          <Col span={12}>
            <InputNumberFormItem placeholder="13,42234" label="Parámetro TI" paramName="TI" />
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <InputFormItem placeholder="Estación AEMET" label="Estación AEMET" paramName="aemetStation" />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ParamsModal;
