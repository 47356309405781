import React, { ReactElement, useState } from 'react';
import '../../App.scss';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import {
  Alert, Button, Form, Input,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLoginMutation } from 'services/api';
import { setCredentials } from 'services/auth/slice';
import paths from 'routes/paths';
import styles from './index.module.scss';

const {
  wrapper,
  logo,
  loginButton,
} = styles;

type LoginFormValues = {
  username: string
  password: string
};

function Login(): ReactElement {
  const [login, { isLoading }] = useLoginMutation();
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onFinish = async (values: LoginFormValues) => {
    try {
      const user = await login(values).unwrap();
      dispatch(setCredentials(user));
      navigate(paths.locations.list);
    } catch (error) {
      setShowError(true);
    }
  };
  const onFinishFailed = () => {
    setShowError(true);
  };
  return (
    <div className={wrapper}>
      <img className={logo} src={`${process.env.PUBLIC_URL}/elecnor-logo.png`} alt="logo Elecnor" />
      <div>
        <img src={`${process.env.PUBLIC_URL}/freecooling.png`} alt="logo FreeCooling" />
        <Form
          name="login"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={() => showError && setShowError(false)}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Es obligatorio introducir un nombre de usuario' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Usuario"
              autoComplete="username"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Es obligatorio introducir una contraseña' }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Contraseña"
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item>
            <Button className={loginButton} type="primary" htmlType="submit" loading={isLoading}>
              Acceder
            </Button>
          </Form.Item>
        </Form>
        {showError && <Alert message="Login inválido" type="error" />}
      </div>
    </div>
  );
}

export default Login;
