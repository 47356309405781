export enum Role {
  admin = 'admin',
  superadmin = 'superadmin',
  user = 'user',
}

export enum Permission {
  VIEW_LOCATIONS_LIST,
  VIEW_LOCATIONS_MAP,
  VIEW_LOCATIONS_DETAILS,
  GENERATE_MONTHLY_REPORT,
  DOWNLOAD_MONTHLY_REPORT,
  DOWNLOAD_MONTHLY_CSV_REPORT,
  EDIT_CALCULATION_PARAMETERS,
  VIEW_ALERTS_LIST,
  RECOGNISE_ALERTS,
}

export enum ConnectionStatus {
  VPNConnectionError,
  PLCConnectionError,
  EnergyManagerConnectionError,
  PLCEReadError,
  EnergyManagerReadError,
  ConnectionOk,
  FilterChangeAlarm,
  MachineFailureAlarm,
  FanAlarm,
  MachineInManualRoomAlarm,
}

export type DeviceType = 'EnergyManager' | 'PLC';

export type DataType = 'instantaneousPowerConsumption' | 'externalTemperature' | 'interiorTemperature' | 'interiorHumidity' | 'fanOnOff' | 'theoreticalConsumption' | 'currentMonthTheoreticalConsumption' | 'currentMonthRealConsumption' | 'totalPowerConsumption' | 'currentMonthPowerSavingInKw';

export type Alarm =
| 'filterChangeAlarm'
| 'machine1FailureAlarm'
| 'machine2FailureAlarm'
| 'machine3FailureAlarm'
| 'machine4FailureAlarm'
| 'machine5FailureAlarm'
| 'temperatureAlarm'
| 'fanInManualAlarm'
| 'fanAlarm'
| 'machine1InManualRoomAlarm'
| 'machine2InManualRoomAlarm'
| 'machine3InManualRoomAlarm'
| 'machine4InManualRoomAlarm'
| 'machine5InManualRoomAlarm'
| 'PLCConnectionFailureAlarm'
| 'analyzerConnectionFailureAlarm'
| 'VPNConnectionAlarm';

export type AlarmsState = Record<Alarm, boolean>;

export interface Device {
  id: string,
  type: DeviceType,
  ip: string,
  user: string,
  password: string,
  connectionStatus: number,
  location: string
}

export interface Serie {
  date: string,
  value: number,
  type: DataType
}
export interface LatestValue {
  datetime: string,
  value: number,
  type: DataType
}
export interface LocationParams {
  id: number
  coefficient0: number
  coefficient1: number
  coefficient2: number
  coefficientA: number
  coefficientB: number
  TI: number
  location: string
}

export interface Location {
  id: number
  buildingName: string
  EOCbuildingName: string
  longitude: string
  latitude: string
  postalcode: string
  city: string
  street: string
  streetNumber: string,
  currentMonthPowerSavingInKw: number,
  currentMonthPowerSavingInPercentage: number,
  connectionStatus: ConnectionStatus,
  locationParams?: LocationParams,
  devices: Device[],
  aemetStation: string,
  alarmsState?: AlarmsState
}

export interface Alert {
  id: number
  recognised: boolean
  code: number
  datetime: Date
  location: Location
}
export interface Chart {
  loading: boolean,
  data: Serie[],
  verticallyCentred?: boolean,
  minValueZero?: boolean,
}
