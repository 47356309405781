import React, { ReactNode } from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';
import Styles from './index.module.scss';

const {
  wrapper, iconWrapper, low, high,
} = Styles;

interface ValueProps {
  status?: 'high' | 'low' | 'average',
  value?: string | ReactNode,
  units?: string | ReactNode,
}

function Value({ status = 'average', value, units } : ValueProps) {
  return (
    value && !Number.isNaN(value) ? (
      <div className={wrapper}>
        {status !== 'average' && <ArrowUpOutlined className={`${iconWrapper} ${status === 'low' ? low : high}`} />}
        {' '}
        {value}
        {' '}
        {units}
      </div>
    ) : <div />
  );
}

export default Value;
