import React, { useMemo } from 'react';
import { Button, Result as AntResult, Typography } from 'antd';
import { ResultStatusType } from 'antd/es/result';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import paths from 'routes/paths';
import styles from './index.module.scss';

const { wrapper } = styles;

function ErrorPage() {
  const navigate = useNavigate();
  const error = useRouteError();
  const getSubtitle = (type: ResultStatusType) => {
    switch (type) {
      case '404':
        return 'Esta página no ha sido encontrada';
      default:
        return 'Lo sentimos, ha habido un error al procesar esta ruta';
    }
  };

  const Result = useMemo(() => {
    if (isRouteErrorResponse(error) || error instanceof Response) {
      return (
        <AntResult
          status={error.status.toString() as ResultStatusType}
          title={error.status.toString()}
          subTitle={error.statusText || getSubtitle(error.status.toString() as ResultStatusType)}
          extra={<Button onClick={() => navigate(paths.home)} type="primary">Volver al inicio</Button>}
        />
      );
    }

    if (error instanceof TypeError && process.env.NODE_ENV === 'development') {
      const e: TypeError = error as TypeError;
      return (
        <AntResult
          status="error"
          title="Ups! Algo ha ido mal..."
        >
          <Typography.Title level={2}>
            {`${e.name}: ${e.message}`}
          </Typography.Title>
          <Typography.Title level={3}>
            {e.cause as string }
          </Typography.Title>
          <Typography.Paragraph style={{ whiteSpace: 'pre-wrap' }}>
            {e.stack}
          </Typography.Paragraph>
        </AntResult>
      );
    }

    return (
      <div className={wrapper}>
        <AntResult
          status="error"
          title="Ups! Algo ha ido mal..."
          extra={<Button onClick={() => navigate(paths.home)} type="primary">Volver al inicio</Button>}
        />
      </div>
    );
  }, [error, navigate]);

  return (
    <div className={wrapper}>
      {Result}
    </div>
  );
}
export default ErrorPage;
