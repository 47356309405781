import React, { ReactNode, useState } from 'react';
import { Card } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useParams } from 'react-router';
import { useGetHistoricsQuery } from 'services/api';
import RefetchButton from 'components/RefetchButton';
import { DataType, Serie } from 'types';
import DateSelector from './components/DateSelector';
import styles from './index.module.scss';

const { cardWrapper } = styles;

interface InnerData {
  data: Serie[],
  loading: boolean,
}

interface GraphCardProps {
  title: string,
  type: DataType[],
  render({ data, loading } : InnerData): ReactNode,
}

function GraphCard({ type, title, render } : GraphCardProps) {
  const { locationId } = useParams();
  const [date, setDate] = useState<Dayjs>(dayjs());
  const { data, isFetching, refetch } = useGetHistoricsQuery({
    month: date.format('M'),
    year: date.format('YYYY'),
    location: locationId || '',
    type,
  }, { skip: !locationId });

  return (
    <Card
      className={cardWrapper}
      title={title}
      extra={[
        <DateSelector key="dateselector" value={date} onChange={(value) => { setDate(dayjs(value, 'MMMM YYYY')); }} />,
        <RefetchButton key="refetchButton" onClick={refetch} />,
      ]}
    >
      {render({ data: data ?? [], loading: isFetching })}
    </Card>
  );
}

export default GraphCard;
