import { ConfigProvider, Layout } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { ReactElement, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import locale from 'antd/locale/es_ES';
import 'dayjs/locale/es';
import useIsLoggedIn from 'hooks/useIsLoggedIn';
import paths from 'routes/paths';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Header from '../Header';
import { colors } from '../../configs';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Europe/Madrid');

function MainLayout(): ReactElement {
  const navigate = useNavigate();
  const userIsLoggedIn = useIsLoggedIn();
  useEffect(() => {
    if (!userIsLoggedIn) {
      navigate(paths.login);
    }
  }, [userIsLoggedIn, navigate]);
  return (
    userIsLoggedIn ? (
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: colors.primaryColor,
            colorLink: colors.primaryColor,
          },
        }}
      >
        <Layout>
          <Header />
          <Content style={{ padding: '1em' }}><Outlet /></Content>
        </Layout>
      </ConfigProvider>
    ) : <> </>
  );
}

export default MainLayout;
