import React from 'react';
import { Line } from '@ant-design/plots';
import { DataType, Chart } from 'types';
import dayjs from 'dayjs';
import { colors } from '../../../../../../configs';

interface Label {
  type: DataType,
  value: string
}

interface LineChartProps extends Chart {
  xAxis?: Record<string, unknown>,
  yAxis?: Record<string, unknown>,
  labels?: Label[],
}

function LineChart({
  data, loading, verticallyCentred, yAxis, xAxis, labels, minValueZero = true,
} : LineChartProps) {
  const values = data.map((d) => d.value);
  const minValue = Math.min(...values);
  const maxValue = Math.max(...values);
  const config = {
    data,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    color: [colors.charts.primaryColor, colors.charts.secondaryColor],
    lineStyle: { lineWidth: 3 },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 3000,
      },
    },
    loading,
  };

  const getMinValue = () => {
    if (minValueZero) {
      return verticallyCentred && minValue - 10 > 0 ? minValue - 10 : 0;
    }
    return verticallyCentred ? minValue - 10 : minValue;
  };

  return (
    <Line
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...config}
      xAxis={{
        grid: {
          line: { type: 'solid', style: { stroke: colors.charts.axisColor } },
        },
        tickCount: 5,
        label: {
          formatter: (text) => dayjs.tz(text).format('DD/MM/YYYY'),
        },
        ...xAxis,
      }}
      yAxis={{
        grid: {
          line: { type: 'solid', style: { stroke: colors.charts.axisColor } },
        },
        tickCount: 4,
        min: getMinValue(),
        max: verticallyCentred ? maxValue + 10 : undefined,
        ...yAxis,
      }}
      tooltip={{
        formatter: (datum) => ({
          title: dayjs(datum.date).format('DD/MM/YYYY'),
          name: labels?.find(({ type }) => type === datum.type)?.value ?? (datum.type || 'Valor'),
          value: datum.value.toLocaleString('es', { maximumFractionDigits: 2 }),
        }),
      }}
      legend={{
        position: 'top',
        itemName: {
          formatter: (text) => labels?.find(({ type }) => type === text)?.value ?? text,
        },
      }}
    />
  );
}

export default LineChart;
