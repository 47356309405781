import React, { ReactNode, useEffect } from 'react';
import { Card, Spin } from 'antd';
import { useGetHistoricsQuery } from 'services/api';
import { REFRESH_INTERVAL } from 'configs';
import { DataType } from 'types';
import dayjs from 'dayjs';
import { FieldTimeOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import Value from './components/Value';

const { customCard, dateText } = styles;

interface ConnectedKpiCardProps {
  status: 'high' | 'low' | 'average',
  title: string,
  units?: string | ReactNode,
  type?: DataType,
  locationId?: string,
  showLastDate?: boolean,
}

interface KpiCardProps extends Omit<ConnectedKpiCardProps, 'type' | 'locationId' | 'showLastDate'> {
  value: number | string,
  loading: boolean,
  date?: dayjs.Dayjs
}

function KpiCard({
  title, loading, value, units, status, date,
} : KpiCardProps) {
  return (
    <Card className={`${customCard} ${styles[status]}`}>
      <p>{title}</p>
      {loading ? <Spin size="small" />
        : (
          <>
            <Value status={status} value={value} units={units} />
            {date && (
              <p className={dateText}>
                <FieldTimeOutlined />
                {` ${date?.format('DD/MM/YYYY')}`}
              </p>
            )}
          </>
        )}
    </Card>
  );
}

function ConnectedKpiCard({
  status, title, units, type, locationId, showLastDate,
} : ConnectedKpiCardProps) {
  const {
    data,
    refetch,
    isFetching: loading,
  } = useGetHistoricsQuery({
    location: locationId as string, type: [type as DataType], month: `${dayjs().month() + 1}`, year: `${dayjs().year()}`,
  }, {
    skip: !type || !locationId,
    selectFromResult: (result) => ({
      ...result,
      data: result.data ? result.data[result.data.length - 1] : result.data,
    }),
  });

  const getValue = (value: number) => {
    if (type === 'fanOnOff') {
      return value === 1 ? 'On' : 'Off';
    }
    return value.toLocaleString('es', { maximumFractionDigits: 2 }) || 0;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, REFRESH_INTERVAL);
    return () => { clearInterval(interval); };
  }, [refetch]);

  return (
    <KpiCard
      value={getValue(data?.value || 0)}
      loading={loading}
      status={status}
      title={title}
      units={units}
      date={showLastDate ? dayjs.tz(data?.date) : undefined}
    />
  );
}

export { ConnectedKpiCard, KpiCard };
