import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { Location } from '../../types';

// eslint-disable-next-line import/prefer-default-export
export const updateLocation = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.mutation<
Location, { id: string, location: Partial<Location> }>({
  query: ({ id, location }) => ({
    url: `/locations/${id}`,
    method: 'PATCH',
    body: location,
  }),
  invalidatesTags: (result, error, { id }) => [{ type: 'Locations', id }],
});
