import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface RefetchButtonProps {
  onClick: () => void
}

function RefetchButton({ onClick }: RefetchButtonProps) {
  return <Button icon={<ReloadOutlined />} onClick={onClick} />;
}

export default RefetchButton;
