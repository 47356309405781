import React, {
  ReactElement, useState, useContext, useMemo, useRef,
  useEffect,
} from 'react';
import {
  Button,
  Row,
  Col,
  Card,
  message,
} from 'antd';
import {
  UnorderedListOutlined,
  FlagOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ReportModal from 'components/ReportModal';
import PageHeader from 'components/PageHeader';
import AuthContext from 'contexts/AuthContext';
import { Permission } from 'types';
import RefetchButton from 'components/RefetchButton';
import { useGetLocationsActiveAlarmsQuery, useGetLocationsQuery } from 'services/api';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/reduxStore';
import styles from './index.module.scss';

const { link } = styles;

function Section(): ReactElement {
  const navigate = useNavigate();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const { hasPermissions } = useContext(AuthContext);
  const [isRefetching, setIsRefetching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const { refetch: refetchAlarmBadge } = useGetLocationsActiveAlarmsQuery(undefined);
  const {
    data: locations,
    isFetching,
    refetch,
  } = useGetLocationsQuery({}, { skip: pathname.includes('detail'), refetchOnMountOrArgChange: true });
  const handleOk = (date: dayjs.Dayjs | null) => {
    if (date) {
      const fetchReport = () => {
        fetch(
          `${process.env.REACT_APP_BACKEND_URL ?? 'http://localhost/'}data/reports/download/xlsx?month=${date.format('MM')}&year=${date.format('YYYY')}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheetz',
              authorization: `bearer ${accessToken}`,
            },
          },
        )
          .then((res) => {
            if (!res.ok) {
              return Promise.reject(res);
            }
            return res.blob();
          })
          .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const a = linkRef.current;
            if (a) {
              a.download = `${date.format('MM')}/${date.format('YYYY')}.xlsx`;
              a.href = href;
              a.click();
              a.href = '';
              setLoading(false);
              setIsModalOpen(false);
            }
          })
          .catch((error) => {
            setLoading(false);
            messageApi.open({
              type: 'error',
              content: 'El reporte todavía no ha sido generado',
            });
          });
      };
      setLoading(true);
      fetchReport();
    }
  };

  useEffect(() => {
    if (!pathname.includes('detail')) {
      refetch();
    }
  }, [pathname, refetch]);

  useEffect(() => {
    if (locations?.data) {
      refetchAlarmBadge();
    }
  }, [locations?.data, refetchAlarmBadge]);

  const ExtraButtons = useMemo(() => {
    const buttons = [
      <Button
        disabled={pathname.includes('list') || !hasPermissions(Permission.VIEW_LOCATIONS_LIST)}
        key="0"
        onClick={() => navigate('list')}
      >
        <UnorderedListOutlined />
        Lista
      </Button>,
      <Button
        disabled={pathname.includes('map') || !hasPermissions(Permission.VIEW_LOCATIONS_MAP)}
        type="default"
        key="1"
        onClick={() => navigate('map')}
      >
        <FlagOutlined />
        Mapa
      </Button>,
    ];

    if (hasPermissions(Permission.GENERATE_MONTHLY_REPORT)) {
      buttons.push(
        <Button
          type="primary"
          key="2"
          onClick={() => setIsModalOpen(true)}
        >
          <FilePdfOutlined />
          Generar reporte
        </Button>,
      );
    }

    return buttons;
  }, [hasPermissions, navigate, pathname]);

  const Content = useMemo(() => (
    <>
      {contextHolder}
      <Row>
        <Col span={24}>
          <PageHeader
            title="Emplazamientos"
            extra={ExtraButtons}
            refetchButton={!pathname.includes('detail') ? <RefetchButton onClick={() => { refetch(); setIsRefetching(true); }} /> : undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Outlet context={{
            locations: locations?.data, isFetching, setIsRefetching, isRefetching,
          }}
          />
        </Col>
      </Row>
    </>
  ), [ExtraButtons, contextHolder, isFetching, isRefetching, locations, pathname, refetch]);

  return (
    <>
      {!pathname.includes('detail') ? <Card>{Content}</Card> : Content}
      <ReportModal
        buttonText="Generar Reporte"
        isModalOpen={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={handleOk}
        hasPermissions={hasPermissions(Permission.DOWNLOAD_MONTHLY_REPORT)}
        allowCurrentMonth={false}
        loading={loading}
      />
      <a ref={linkRef} href="/" className={link}>Link</a>
    </>
  );
}

export default Section;
