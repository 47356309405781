import React from 'react';
import styles from './index.module.scss';

const { wrapper } = styles;

interface PillProps {
  children: string,
  status: 'active' | 'inactive',
  onClick?: () => void,
}

function Pill({ children, status, onClick } : PillProps) {
  return (
    <span
      className={`${wrapper} ${styles[status]}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      {children}
    </span>
  );
}

export default Pill;
