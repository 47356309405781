import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';

export interface UserResponse {
  user_id:string
  payload: {
    token: string
    refresh_token: string
  }
}

export interface RefreshTokenResponse {
  user_id:string
  payload: {
    token: string
    refresh_token: string
  }
}

export interface LoginRequest {
  username: string
  password: string
}

export interface RefreshTokenRequest {
  refresh_token: string
}

export const login = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.mutation<UserResponse, LoginRequest>({
  query: (credentials) => ({
    url: '/auth/login',
    method: 'POST',
    body: credentials,
  }),
});

export const refreshToken = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.mutation<RefreshTokenResponse, RefreshTokenRequest>({
  query: (rt) => ({
    url: '/auth/refresh',
    method: 'POST',
    body: rt,
  }),
});
