import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { Alert } from '../../types';

export interface Page<T> {
  data: T[],
  total: number
}

export const getAlerts = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<
Page<Alert>,
{
  page?: number,
  limit?: number,
  orderByColumn?: string,
  orderByAscOrDesc?: string,
  populate?: boolean,
  recognised?: boolean,
  startDate?: string,
  endDate?: string,
  location?: number,
}>({
  query: ({
    populate,
    page,
    limit,
    orderByAscOrDesc,
    orderByColumn,
    recognised,
    location,
    startDate,
    endDate,
  }) => `/alerts?populate=${populate}&page=${page}&limit=${limit}&orderByColumn=${orderByColumn}&orderByAscOrDesc=${orderByAscOrDesc}${recognised !== undefined ? `&recognised=${recognised}` : ''}${location ? `&location=${location}` : ''}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`,
  providesTags: (result) => (result?.data
    ? [
      ...result.data.map(({ id }) => ({ type: 'Alerts', id: id.toString() } as const)),
      { type: 'Alerts', id: 'LIST' },
    ]
    : [{ type: 'Alerts', id: 'LIST' }]),
});

export const getAlertById = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<Alert, string>({
  query: (alertId) => `/alerts/${alertId}`,
});
