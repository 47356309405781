import React, {
  ReactElement, useEffect, useState,
} from 'react';
import '../../../App.scss';
import {
  Button, Table, Radio, Row, Col, Popconfirm, Card,
} from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { PaginationProps } from 'antd/es/pagination';
import { useNavigate } from 'react-router';
import { Alert } from 'types';
import PageHeader from 'components/PageHeader';
import RefetchButton from 'components/RefetchButton';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import {
  api, useGetAlertsQuery, useGetLocationsQuery, useRecogniseAlertMutation,
} from '../../../services/api';
import getColumns from './columns';

interface Pagination {
  current?: number;
  defaultCurrent: number;
  total: number;
  pageSize?: number;
  defaultPageSize: number;
}

interface Sorter {
  orderByColumn?: string,
  orderByAscOrDesc?: 'ASC' | 'DESC'
}

type Filter = Record<string, unknown | undefined>;

function HistoricsList(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [pagination, setPagination] = useState<Pagination>({
    current: 1, defaultCurrent: 1, total: 0, pageSize: 10, defaultPageSize: 10,
  });
  const [{ orderByAscOrDesc, orderByColumn }, setSorter] = useState<Sorter>({ orderByAscOrDesc: 'DESC', orderByColumn: 'datetime' });
  const [filter, setFilter] = useState<Filter>();
  const [selectionType, setSelectionType] = useState<'checkbox' | 'radio'>('checkbox');
  const {
    data,
    isFetching: isLoading,
    refetch,
  } = useGetAlertsQuery({
    populate: true,
    page: pagination.current,
    limit: pagination.pageSize,
    orderByColumn,
    orderByAscOrDesc,
    recognised: filter?.recognised as boolean,
    startDate: filter?.startDate as string,
    endDate: filter?.endDate as string,
    location: filter?.location as number,
  });
  const [
    recogniseAlert,
    { isLoading: isUpdating },
  ] = useRecogniseAlertMutation();

  const { data: locations } = useGetLocationsQuery({});

  const { data: alerts, total } = data || {};

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <Button>Anterior</Button>;
    }
    if (type === 'next') {
      return <Button>Siguiente</Button>;
    }
    return originalElement;
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    getCheckboxProps: (record: Alert) => ({
      name: record.id.toString(),
      disabled: record.recognised,
    }),
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const onRecogniseButtonClick = () => {
    selectedRowKeys
      .forEach((id) => { recogniseAlert(id.toString()); });
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (total) {
      setPagination((prev) => ({ ...prev, total }));
    }
  }, [total]);

  useEffect(() => {
    dispatch(api.util.invalidateTags(['Alerts']));
  }, [dispatch]);

  return (
    <Card>
      <Row>
        <Col span={24}>
          <PageHeader
            title="Alarmas"
            onBack={() => navigate('/alerts/list')}
            extra={[
              <Popconfirm
                title="Reconocer alerta"
                description={`¿Estás seguro de que quieres reconocer esta${selectedRowKeys.length > 1 ? 's' : ''} alarmas${selectedRowKeys.length > 1 ? 's' : ''}?`}
                onConfirm={onRecogniseButtonClick}
                okText="Sí"
                cancelText="No"
                key="recogniseAlert"
              >
                <Button key="0" disabled={!hasSelected}>
                  <CheckCircleOutlined />
                  Reconocer alarmas seleccionadas
                </Button>
              </Popconfirm>,
            ]}
            refetchButton={(
              <RefetchButton onClick={() => {
                dispatch(api.util.invalidateTags(['Alerts']));
                refetch();
              }}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Radio.Group
            onChange={({ target: { value } }) => {
              setSelectionType(value);
            }}
            value={selectionType}
          />
          <Table
            loading={isLoading || isUpdating}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            dataSource={alerts?.map((alert) => ({
              key: alert.id,
              ...alert,
            }))}
            columns={getColumns({
              locations: locations?.data || [],
            })}
            pagination={{ itemRender, showSizeChanger: true, ...pagination }}
            onChange={({
              current,
              pageSize,
            }, filters, sorters) => {
              if (sorters && !Array.isArray(sorters)) {
                setSorter({ orderByColumn: sorters.columnKey as string, orderByAscOrDesc: sorters.order?.includes('asc') ? 'ASC' : 'DESC' });
              }
              setFilter(Object
                .keys(filters)
                .reduce((acc: Record<string, unknown | undefined>, cur: string) => {
                  if (cur === 'datetime') {
                    if (filters.datetime?.[0]) {
                      const [start, end] = (filters.datetime[0] as string).split(',');
                      acc.startDate = (dayjs(start)).toISOString();
                      acc.endDate = (dayjs(end)).add(1, 'day').toISOString();
                    } else {
                      acc.startDate = null;
                      acc.endDate = null;
                    }
                  } else {
                    acc[cur] = filters[cur]?.[0];
                  }
                  return acc;
                }, {}));
              setPagination((prev) => ({
                ...prev,
                current,
                pageSize,
              }));
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default HistoricsList;
