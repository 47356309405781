import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { colors } from '../../configs';

interface SearchFilterIconProps {
  filtered: boolean,
}

function SearchFilterIcon({
  filtered,
}: SearchFilterIconProps): JSX.Element {
  return (
    <SearchOutlined style={{ color: filtered ? colors.primaryColor : undefined }} />
  );
}

export default SearchFilterIcon;
