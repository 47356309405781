import {
  BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';
import type { RootState } from '../reduxStore';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL ?? 'http://localhost/',
  prepareHeaders: async (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth;
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
string | FetchArgs,
unknown,
FetchBaseQueryError // | BackendError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    const refreshResult = await baseQuery(
      {
        method: 'POST',
        url: '/auth/refresh',
        body: {
          refresh_token: (api.getState() as RootState).auth.refreshToken as string,
        },
      },
      api,
      extraOptions,
    );
    if (refreshResult.data) {
      api.dispatch({ type: 'auth/setAccessToken', payload: refreshResult.data });
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch({
        type: 'auth/logOut',
      });
    }
  }
  return result;
};

export default baseQueryWithReauth;
