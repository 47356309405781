import React from 'react';
import { Form, InputNumber as AntdInputNumber } from 'antd';

interface Props {
  label: string,
  paramName: string,
  placeholder: string,
}

function InputNumberFormItem({
  label,
  paramName,
  placeholder,
}: Props): JSX.Element {
  return (
    <Form.Item
      label={label}
      name={paramName}
      rules={[
        {
          required: true,
          message: 'Este campo es obligatorio',
        },
      ]}
    >
      <AntdInputNumber
        placeholder={placeholder}
        style={{ width: '90%' }}
        decimalSeparator=","
      />
    </Form.Item>
  );
}

export default InputNumberFormItem;
