import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Input, InputRef, Space,
} from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';

interface Props {
  inputRef: React.RefObject<InputRef> | undefined,
  title: string,
  selectedKeys: React.Key[],
  setSelectedKeys: (selectedKeys: React.Key[]) => void,
  confirm: (param?: FilterConfirmProps) => void,
  close: () => void,
}

function SearchFilterDropdown({
  inputRef,
  title,
  selectedKeys,
  setSelectedKeys,
  confirm,
  close,
}: Props): JSX.Element {
  return (
    <div style={{ padding: 8 }} aria-hidden onKeyDown={(e) => e.stopPropagation()}>
      <Input
        ref={inputRef}
        placeholder={`Buscar por ${title}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value !== '' ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Buscar
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          Cerrar
        </Button>
      </Space>
    </div>

  );
}

export default SearchFilterDropdown;
