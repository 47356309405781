import React, { useState } from 'react';
import {
  Button, DatePicker, Modal, Spin,
} from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import styles from './index.module.scss';

dayjs.extend(isBetween);

const { spinWrapper } = styles;

interface Props {
  isModalOpen: boolean,
  buttonText: string,
  onOk: (date: dayjs.Dayjs | null) => void,
  onCancel: () => void,
  hasPermissions: boolean,
  allowCurrentMonth?: boolean,
  loading?: boolean,
}

function ReportModal({
  isModalOpen,
  onOk,
  buttonText,
  onCancel,
  hasPermissions = false,
  allowCurrentMonth = true,
  loading = false,
}: Props): JSX.Element {
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);

  return (
    <Modal
      title="Generar reporte mensual"
      open={isModalOpen}
      onCancel={onCancel}
      confirmLoading
      footer={[
        <Button
          key="link"
          type="primary"
          onClick={() => onOk(date)}
          disabled={!date || !hasPermissions}
        >
          {buttonText}
        </Button>,
      ]}
    >
      {loading && (
        <div className={spinWrapper}>
          <Spin />
        </div>
      )}
      <p>
        Escoja a continuación uno de los últimos 12 meses para
        generar un informe completo en formato excel:
      </p>
      <DatePicker
        placeholder="Escoger mes"
        format="MMMM YYYY"
        defaultValue={dayjs()}
        onChange={(value) => setDate(value)}
        picker="month"
        value={date}
        disabledDate={(d) => !d.isBetween(allowCurrentMonth ? dayjs() : dayjs().clone().subtract(1, 'month'), dayjs().clone().subtract(11, 'month'))}
      />
    </Modal>
  );
}

export default ReportModal;
