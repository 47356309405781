import React from 'react';
import { Link } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { Location } from '../../../types';
import alarmsData from '../../../configs/alarms';

const getLinkedLocation = (location: Location): string => `/locations/detail/${location.id}`;

function ErrorIcon() {
  return <CloseOutlined style={{ color: '#ff4d4f' }} />;
}

function OkIcon() {
  return <CheckOutlined style={{ color: '#52c41a' }} />;
}

const getIcon = (value?: boolean) => {
  if (value === undefined || typeof value !== 'boolean') {
    return '-';
  }
  return value ? <ErrorIcon /> : <OkIcon />;
};

const columns : ColumnType<Location>[] = [
  {
    title: 'Emplazamiento',
    dataIndex: 'buildingName',
    key: 'buildingName',
    fixed: 'left',
    width: '300px',
    render(value, location) {
      if (value) {
        return (
          <Link to={getLinkedLocation(location)}>
            {value}
          </Link>
        );
      }
      return value;
    },
    defaultSortOrder: 'ascend',
    sorter: true,
  },
  ...alarmsData.map(({ label, value }) => ({
    title: label,
    dataIndex: value,
    key: value,
    align: 'center',
    width: '150px',
    render: (_value, location) => getIcon(location.alarmsState?.[value]),
  })) as ColumnType<Location>[],
];
export default columns;
