import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Button, message,
} from 'antd';
import { FileExcelOutlined, LineChartOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import ReportModal from 'components/ReportModal';
import AuthContext from 'contexts/AuthContext';
import { Permission, Location } from 'types';
import { RootState } from 'services/reduxStore';
import ParamsModal from './components/ParamsModal';
import StatusTable from './components/StatusTable';
import styles from './index.module.scss';

const { wrapper, link } = styles;

interface PageHeaderContentProps {
  location?: Location
}

function PageHeaderContent({ location } : PageHeaderContentProps) {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const accessToken = useSelector((state: RootState) => state.auth.accessToken);
  const [isParamsModalOpen, setIsParamsModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [date, setDate] = useState<Dayjs | null>(null);
  const [messageApi, contextHolder] = message.useMessage();

  const { hasPermissions } = useContext(AuthContext);
  const { EDIT_CALCULATION_PARAMETERS, DOWNLOAD_MONTHLY_CSV_REPORT } = Permission;

  useEffect(() => {
    if (date) {
      const fetchReport = () => {
        fetch(
          `${process.env.REACT_APP_BACKEND_URL ?? 'http://localhost/'}data/historics/download/csv?month=${date.format('MM')}&year=${date.format('YYYY')}&location=${location?.id}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'text/csv',
              authorization: `bearer ${accessToken}`,
            },
          },
        )
          .then((res) => {
            if (!res.ok) {
              return Promise.reject(res);
            }
            return res.blob();
          })
          .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const a = linkRef.current;
            if (a) {
              a.download = `${date.format('MM')}/${date.format('YYYY')}.csv`;
              a.href = href;
              a.click();
              a.href = '';
              setDate(null);
              setIsReportModalOpen(false);
            }
          })
          .catch((error) => {
            messageApi.open({
              type: 'error',
              content: 'No se ha encontrado el fichero',
            });
          });
      };

      fetchReport();
    }
  }, [accessToken, date, location, messageApi]);

  return (
    <>
      {contextHolder}
      <div className={wrapper}>
        <div>
          {hasPermissions(EDIT_CALCULATION_PARAMETERS) && (
            <Button
              type="primary"
              key="2"
              onClick={() => setIsParamsModalOpen(true)}
            >
              <LineChartOutlined />
              Modificar parámetros de referencia
            </Button>
          )}
          {' '}
          {hasPermissions(DOWNLOAD_MONTHLY_CSV_REPORT) && (
            <Button
              type="primary"
              key="1"
              onClick={() => setIsReportModalOpen(true)}
            >
              <FileExcelOutlined />
              Descargar datos en CSV
            </Button>
          )}
        </div>
        {location && isParamsModalOpen && (
          <ParamsModal
            isModalOpen={isParamsModalOpen}
            setIsModalOpen={setIsParamsModalOpen}
            location={location}
          />
        )}
        <ReportModal
          buttonText="Generar CSV"
          isModalOpen={isReportModalOpen}
          onOk={setDate}
          onCancel={() => setIsReportModalOpen(false)}
          hasPermissions={hasPermissions(Permission.DOWNLOAD_MONTHLY_CSV_REPORT)}
        />
        {location && <StatusTable location={location} />}
        <a ref={linkRef} href="/" className={link}>Link</a>
      </div>
    </>
  );
}

export default PageHeaderContent;
