import { createApi } from '@reduxjs/toolkit/query/react';
import { recogniseAlert } from '../alerts/mutations';
import { getAlertById, getAlerts } from '../alerts/queries';
import { login, refreshToken } from '../auth/mutations';
import { getHistorics, getHistoricsLatestValues } from '../historics/queries';
import { createLocationParams, deleteLocationParams, updateLocationParams } from '../location-params/mutations';
import { getLocationParams, getLocationParamsById } from '../location-params/queries';
import { updateLocation } from '../locations/mutations';
import { getLocationById, getLocations, getLocationsActiveAlarms } from '../locations/queries';
import baseQueryWithReauth from './customBaseQuery';

export const api = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Alerts', 'Locations', 'Historics', 'Counter'],
  endpoints: (builder) => ({

    // Auth
    login: login(builder),
    refreshToken: refreshToken(builder),

    // Locations
    getLocations: getLocations(builder),
    getLocationsActiveAlarms: getLocationsActiveAlarms(builder),
    getLocationById: getLocationById(builder),
    updateLocation: updateLocation(builder),

    // Alerts
    getAlerts: getAlerts(builder),
    getAlertById: getAlertById(builder),
    recogniseAlert: recogniseAlert(builder),

    // Locations Params
    getLocationParams: getLocationParams(builder),
    getLocationParamsById: getLocationParamsById(builder),
    createLocationParams: createLocationParams(builder),
    updateLocationParams: updateLocationParams(builder),
    deleteLocationParams: deleteLocationParams(builder),

    // Historics
    getHistorics: getHistorics(builder),
    getHistoricsLatestValues: getHistoricsLatestValues(builder),
  }),
});
export const {
  useLoginMutation,
  useRefreshTokenMutation,
  useGetLocationsQuery,
  useGetLocationByIdQuery,
  useUpdateLocationMutation,
  useGetAlertsQuery,
  useGetAlertByIdQuery,
  useRecogniseAlertMutation,
  useGetLocationParamsQuery,
  useGetLocationParamsByIdQuery,
  useCreateLocationParamsMutation,
  useUpdateLocationParamsMutation,
  useDeleteLocationParamsMutation,
  useGetHistoricsQuery,
  useGetHistoricsLatestValuesQuery,
  useGetLocationsActiveAlarmsQuery,
} = api;
