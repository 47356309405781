import React, { useMemo } from 'react';
import { Select } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import { DefaultOptionType } from 'antd/lib/cascader';

const { Option } = Select;

dayjs.locale('es');

interface DateSelectorProps {
  onChange: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void,
  value: Dayjs
}

function DateSelector({ onChange, value } : DateSelectorProps) {
  const today = dayjs();
  const formattedValue = value.format('MMMM YYYY');
  const options = useMemo(() => {
    const optionsList = [];
    for (let index = 0; index < 12; index += 1) {
      const date = today.clone().subtract(index, 'month').format('MMMM YYYY');
      optionsList.push(<Option key={date} value={date}>{date}</Option>);
    }
    return optionsList;
  }, [today]);
  return (
    <Select defaultValue={formattedValue} onChange={onChange} value={formattedValue}>
      {options}
    </Select>
  );
}

export default DateSelector;
