import React from 'react';
import {
  Badge, Button, DatePicker, Select, Space,
} from 'antd';
import { PresetStatusColorType } from 'antd/lib/_util/colors';
import { Link } from 'react-router-dom';
import { Alert, Location } from 'types';
import { FilterDropdownProps, SortOrder } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

const {
  connection,
} = styles;

const getStatus = (recognized: boolean): PresetStatusColorType => {
  switch (recognized) {
    case true:
      return 'success';
    case false:
      return 'error';
    default:
      return 'processing';
  }
};

const getCodeMessage = (code: number): string => {
  switch (code) {
    case 0:
      return 'Error de conexión VPN';
    case 1:
    case 3:
      return 'Error de conexión en PLC';
    case 2:
    case 4:
      return 'Error de conexión en GE';
    case 6:
      return 'Cambio de filtro';
    case 7:
      return 'Fallo en M1';
    case 8:
      return 'Fallo en M2';
    case 9:
      return 'Fallo en M3';
    case 10:
      return 'Fallo en M4';
    case 11:
      return 'Fallo en M5';
    case 12:
      return 'Ventilador';
    case 13:
      return 'Ventilador en manual';
    case 14:
      return 'Temperatura';
    case 15:
      return 'M1 en manual';
    case 16:
      return 'M2 en manual';
    case 17:
      return 'M3 en manual';
    case 18:
      return 'M4 en manual';
    case 19:
      return 'M5 en manual';
    case 20:
      return 'PLC';
    case 21:
      return 'Gestor de red';
    case 22:
      return 'VPN';
    default:
      return 'Mensaje con código no especificado';
  }
};

const getDisplayDate = (date: Date): string => new Date(date).toLocaleString();

const getLinkedLocation = (location: Location): string => `/locations/detail/${location.id}`;

const getColumns = ({ locations }: {
  locations: Location[],
}) => ([
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    render: (value: number) => <span>{value.toString()}</span>,
  },
  {
    title: 'Reconocida',
    dataIndex: 'recognised',
    key: 'recognised',
    filterMultiple: false,
    filters: [
      {
        text: 'Reconocida',
        value: true,
      },
      {
        text: 'No reconocida',
        value: false,
      },
    ],
    onFilter: (value: string | number | boolean, record: Alert) => record.recognised === (value),
    render(value: boolean) {
      return (
        <div>
          <Badge className={connection} status={getStatus(value)} />
          {' '}
          {(value) === true ? 'Reconocida' : 'No reconocida'}
        </div>
      );
    },
  },
  {
    title: 'Mensaje',
    dataIndex: 'code',
    key: 'code',
    render(value: number) {
      return (
        <div>
          {getCodeMessage(value)}
        </div>
      );
    },
  },
  {
    title: 'Fecha',
    dataIndex: 'datetime',
    key: 'datetime',
    defaultSortOrder: 'descend' as SortOrder,
    filterMultiple: false,
    sorter: (a: Alert, b: Alert) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime(),
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters, close,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} aria-hidden onKeyDown={(e) => e.stopPropagation()}>
        <DatePicker.RangePicker
          onChange={(dates, datesStr) => {
            if (datesStr.some((d) => !d)) {
              setSelectedKeys([]);
            } else {
              setSelectedKeys(datesStr ? [datesStr.join(',')] : []);
            }
          }}
          style={{ width: '100%', marginBottom: 8 }}
          allowClear
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>

    ),
    render(value: Date) {
      return (
        <div>
          {getDisplayDate(value)}
        </div>
      );
    },
  },
  {
    title: 'Emplazamiento',
    dataIndex: 'location',
    key: 'location',
    render(value: Location) {
      if (value) {
        return (
          <Link to={getLinkedLocation(value)}>
            {value.buildingName}
          </Link>
        );
      }
      return value;
    },
    onFilter: () => true,
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters, close,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8, minWidth: '250px' }} aria-hidden onKeyDown={(e) => e.stopPropagation()}>
        <Select
          options={locations.map((location) => ({
            label: location.buildingName,
            value: location.id,
          }))}
          onClear={() => setSelectedKeys([])}
          onSelect={(value) => {
            setSelectedKeys(value ? [value] : []);
          }}
          style={{ width: '100%', marginBottom: 8 }}
          allowClear
          showSearch
          optionFilterProp="label"
          filterSort={(optionA, optionB) => (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Cerrar
          </Button>
        </Space>
      </div>

    ),
  },
]);
export default getColumns;
