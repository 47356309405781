import React, { ReactElement, useRef } from 'react';
import {
  Button,
  Table,
  InputRef,
} from 'antd';
import { PaginationProps } from 'antd/es/pagination';
import { Link, useOutletContext } from 'react-router-dom';
import PowerSavingsValue from 'components/PowerSavingsValue';
import { Location } from 'types';
import columns from './columns';
import { LocationRow, LocationsOutletContext } from '../types';
import styles from './index.module.scss';

const { powerSavings } = styles;

function LocationsList(): ReactElement {
  const searchInput = useRef<InputRef>(null);
  const {
    locations, isFetching,
  } = useOutletContext<LocationsOutletContext>();

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <Button>Anterior</Button>;
    }
    if (type === 'next') {
      return <Button>Siguiente</Button>;
    }
    return originalElement;
  };

  const locationsColumn = (): LocationRow[] | undefined => locations?.map((location: Location) => ({
    ...location,
    key: location.id,
    buildingName:
    (
      <Link to={`/locations/detail/${location.id}`}>
        {location.buildingName}
      </Link>
    ),
    currentMonthPowerSavingInPercentage: <PowerSavingsValue
      currentMonthPowerSavingInKw={location.currentMonthPowerSavingInKw}
      currentMonthPowerSavingInPercentage={location.currentMonthPowerSavingInPercentage}
      className={powerSavings}
    />,
    rawObject: location,
  }));

  return (
    <Table
      loading={isFetching}
      dataSource={locationsColumn()}
      columns={columns(searchInput)}
      pagination={{ itemRender, showSizeChanger: true }}
    />
  );
}

export default LocationsList;
