import React from 'react';
import { Popup } from 'react-leaflet';
import { Alert } from 'antd';
import { Link } from 'react-router-dom';
import { ConnectionStatus, Location } from 'types';
import PowerSavingsValue from 'components/PowerSavingsValue';
import ConnectionStatusDescription from 'components/ConnectionStatusDescription';
import styles from './index.module.scss';

interface LocationPopupProps {
  location: Location,
}

const { powerSavingsValue, wrapper } = styles;

function LocationPopup({
  location: {
    buildingName, id, currentMonthPowerSavingInKw,
    currentMonthPowerSavingInPercentage, connectionStatus,
  },
} : LocationPopupProps) {
  return (
    <Popup key={id} className={wrapper}>
      <h3 style={{ display: 'flex' }}>
        <ConnectionStatusDescription value={connectionStatus} badgeSize="large" onlyBadge />
        {' '}
        <Link to={`../detail/${id}`}>{buildingName}</Link>
      </h3>
      {currentMonthPowerSavingInPercentage && currentMonthPowerSavingInKw ? (
        <p>
          <PowerSavingsValue
            currentMonthPowerSavingInPercentage={currentMonthPowerSavingInPercentage}
            currentMonthPowerSavingInKw={currentMonthPowerSavingInKw}
            className={powerSavingsValue}
          />
        </p>
      ) : null}
      {connectionStatus !== ConnectionStatus.ConnectionOk
      && <Alert message={<ConnectionStatusDescription value={connectionStatus} onlyDescription />} type="error" />}
    </Popup>
  );
}

export default LocationPopup;
