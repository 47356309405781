import React, { ReactElement, useEffect, useState } from 'react';
import '../../../App.scss';
import {
  Button, Table, Row, Col, Card,
  Switch,
} from 'antd';
import { useNavigate } from 'react-router';
import PageHeader from 'components/PageHeader';
import RefetchButton from 'components/RefetchButton';
import { useDispatch } from 'react-redux';
import {
  api, useGetLocationsActiveAlarmsQuery, useGetLocationsQuery,
} from '../../../services/api';
import columns from './columns';

interface Pagination {
  current?: number;
  defaultCurrent: number;
  total: number;
  pageSize?: number;
  defaultPageSize: number;
}

interface Sorter {
  orderByColumn?: string,
  orderByAscOrDesc?: 'ASC' | 'DESC'
}

function AlertsList(): ReactElement {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLocationsWithSomeActiveAlarm,
    setShowLocationsWithSomeActiveAlarm] = useState<true | undefined>(true);
  const [pagination, setPagination] = useState<Pagination>({
    current: 1, defaultCurrent: 1, total: 0, pageSize: 10, defaultPageSize: 10,
  });
  const [{ orderByAscOrDesc, orderByColumn }, setSorter] = useState<Sorter>({ orderByAscOrDesc: 'ASC', orderByColumn: 'buildingName' });
  const { refetch: refetchAlarmBadge } = useGetLocationsActiveAlarmsQuery(undefined);

  const {
    data: locations,
    isFetching: isLoading,
    refetch,
  } = useGetLocationsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    orderByColumn,
    orderByAscOrDesc,
    activeAlarms: showLocationsWithSomeActiveAlarm,
  }, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (locations?.total) {
      refetchAlarmBadge();
      setPagination((prev) => ({ ...prev, total: locations.total }));
    }
  }, [locations?.total, refetchAlarmBadge]);

  return (
    <Card>
      <Row>
        <Col span={24}>
          <PageHeader
            title="Alarmas"
            onBack={() => navigate('/locations/list')}
            extra={[
              <Switch
                key="switch"
                onChange={(checked) => {
                  setShowLocationsWithSomeActiveAlarm(checked === true || undefined);
                }}
                size="default"
                checked={Boolean(showLocationsWithSomeActiveAlarm)}
                unCheckedChildren="Ver todo"
                checkedChildren="Ver alarmas activas"
              />,
              <Button
                key="historics"
                onClick={() => navigate('/alerts/historics')}
              >
                Ver histórico de alarmas
              </Button>,
            ]}
            refetchButton={(
              <RefetchButton onClick={() => {
                dispatch(api.util.invalidateTags(['Alerts']));
                refetch();
              }}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            rowKey="id"
            loading={isLoading}
            dataSource={locations?.data}
            columns={columns}
            scroll={{ x: 1500 }}
            pagination={{ showSizeChanger: true, ...pagination }}
            onChange={({
              current,
              pageSize,
            }, filters, sorters) => {
              if (sorters && !Array.isArray(sorters)) {
                setSorter({ orderByColumn: sorters.columnKey as string, orderByAscOrDesc: sorters.order?.includes('asc') ? 'ASC' : 'DESC' });
              }
              setPagination((prev) => ({
                ...prev,
                current,
                pageSize,
              }));
            }}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default AlertsList;
