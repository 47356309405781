import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'services/reduxStore';
import { Permission, Role } from 'types';

const permissionsAssignment : Record<Role, Permission[]> = {
  [Role.superadmin]: [
    Permission.VIEW_LOCATIONS_LIST,
    Permission.VIEW_LOCATIONS_MAP,
    Permission.VIEW_LOCATIONS_DETAILS,
    Permission.GENERATE_MONTHLY_REPORT,
    Permission.DOWNLOAD_MONTHLY_REPORT,
    Permission.DOWNLOAD_MONTHLY_CSV_REPORT,
    Permission.EDIT_CALCULATION_PARAMETERS,
    Permission.VIEW_ALERTS_LIST,
    Permission.RECOGNISE_ALERTS,
  ],
  [Role.admin]: [
    Permission.VIEW_LOCATIONS_LIST,
    Permission.VIEW_LOCATIONS_MAP,
    Permission.VIEW_LOCATIONS_DETAILS,
    Permission.GENERATE_MONTHLY_REPORT,
    Permission.DOWNLOAD_MONTHLY_REPORT,
    Permission.DOWNLOAD_MONTHLY_CSV_REPORT,
    Permission.EDIT_CALCULATION_PARAMETERS,
    Permission.VIEW_ALERTS_LIST,
    Permission.RECOGNISE_ALERTS,
  ],
  [Role.user]: [
    Permission.VIEW_LOCATIONS_LIST,
    Permission.VIEW_LOCATIONS_MAP,
    Permission.VIEW_LOCATIONS_DETAILS,
  ],
};

interface AuthProviderProps {
  children: JSX.Element
}

interface AuthContent {
  hasPermissions: (permissions: Permission) => boolean,
}

const AuthContext = React.createContext < AuthContent | Record<string, never>>({});

function AuthProvider({ children } : AuthProviderProps) {
  const role = useSelector(({ auth } : RootState) => auth.roles?.[0]);

  const hasPermissions = useCallback((permission: Permission) : boolean => {
    if (!role) { return false; }
    return permissionsAssignment[role].includes(permission);
  }, [role]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AuthContext.Provider value={{ hasPermissions }}>
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider };
export type { AuthContent };
export default AuthContext;
