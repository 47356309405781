import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { LocationParams } from '../../types';

export const getLocationParams = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<
LocationParams[],
{
  page?: number,
  limit?: number,
  orderByColumn?: string,
  orderByAscOrDesc?: string,
  populate?: boolean,
}>({
  query: () => '/location-params',
});

export const getLocationParamsById = (
  builder: EndpointBuilder<BaseQueryFn, string, string>,
) => builder.query<LocationParams, string>({
  query: (paramsId) => `/location-params/${paramsId}`,
});
