import React from 'react';
import { ArrowUpOutlined } from '@ant-design/icons';
import Styles from './index.module.scss';

interface PowerSavingsValueProps {
  currentMonthPowerSavingInKw: number,
  currentMonthPowerSavingInPercentage: number,
  className?: string
}

const {
  positiveSavings, negativeSavings, arrowUp, arrowDown, noDataValue,
} = Styles;

const SAVING_THRESHOLD = 0;

function PowerSavingsValue({
  currentMonthPowerSavingInKw,
  currentMonthPowerSavingInPercentage,
  className,
} : PowerSavingsValueProps) {
  const getPositiveOrNegativeColor = (savings: number): string => (
    savings >= SAVING_THRESHOLD ? positiveSavings : negativeSavings
  );

  const getUpOrDown = (savings: number): string => (
    savings >= SAVING_THRESHOLD ? arrowUp : arrowDown
  );

  if (currentMonthPowerSavingInPercentage && currentMonthPowerSavingInKw) {
    return (
      <span className={`${getPositiveOrNegativeColor(currentMonthPowerSavingInPercentage)}${className ? ` ${className}` : ''}`}>
        <ArrowUpOutlined className={getUpOrDown(currentMonthPowerSavingInPercentage)} />
        <span>
          {` ${currentMonthPowerSavingInPercentage.toLocaleString('es', { maximumFractionDigits: 2 })}% /`}
        </span>
        <span>
          {` ${currentMonthPowerSavingInKw.toLocaleString('es', { maximumFractionDigits: 2 })} kW/h`}
        </span>
      </span>

    );
  }
  return (
    <div className={noDataValue}>
      <span>{'-- / '}</span>
      <span>--</span>
    </div>
  );
}

export default PowerSavingsValue;
