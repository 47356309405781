import React, { ReactElement } from 'react';
import {
  Layout, Badge, Button,
  Spin,
} from 'antd';
import { BellOutlined, LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import { logOut } from 'services/auth/slice';
import { useGetLocationsActiveAlarmsQuery } from 'services/api';
import styles from './index.module.scss';

const {
  badge, header,
} = styles;

export default function Header(): ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isFetching } = useGetLocationsActiveAlarmsQuery(undefined);

  return (
    <Layout.Header className={header}>
      <Link to={paths.home}>
        <img src={`${process.env.PUBLIC_URL}/elecnor-logo.png`} alt="elecnor-logo.png" />
      </Link>
      <div onClick={() => navigate(paths.alerts.list)} onKeyDown={() => navigate(paths.alerts.list)} role="button" aria-hidden>
        <Badge className={badge} count={isFetching ? <Spin size="small" /> : data?.counter ?? 0} showZero={false}>
          <Button icon={<BellOutlined />} type="default" onClick={() => navigate(paths.alerts.list)} shape="circle" />
        </Badge>
        <Button
          icon={<LogoutOutlined />}
          type="default"
          onClick={() => {
            dispatch(logOut());
          }}
          shape="circle"
        />
      </div>
    </Layout.Header>
  );
}
