import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from 'types';

export type AuthState = {
  user_id: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  expires: number | null;
  roles: Role[];
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    user_id: null,
    accessToken: localStorage.getItem('access_token') || null,
    refreshToken: localStorage.getItem('refresh_token') || null,
    expires: localStorage.getItem('access_token')
      ? parseJwt(localStorage.getItem('access_token') as string).exp * 1000
      : null,
    roles: localStorage.getItem('access_token')
      ? parseJwt(localStorage.getItem('access_token') as string).roles
      : null,
  } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          user_id: userId,
          payload: {
            token,
            refresh_token: refreshToken,
          },
        },
      }: PayloadAction<{
        user_id: string;
        payload: { token: string; refresh_token: string; }
      }>,
    ) => {
      const jwt = parseJwt(token);
      state.expires = jwt.exp * 1000;
      state.user_id = userId;
      state.accessToken = token;
      state.refreshToken = refreshToken;
      state.roles = jwt.roles;
      localStorage.setItem('access_token', token);
      localStorage.setItem('refresh_token', refreshToken);
    },
    setAccessToken: (
      state,
      {
        payload: {
          payload: {
            token,
          },
        },
      }: PayloadAction<{
        payload: {
          token: string;
        }
      }>,
    ) => {
      const jwt = parseJwt(token);
      localStorage.setItem('access_token', token);
      state.accessToken = token;
      state.expires = jwt.exp * 1000;
    },
    logOut: (state) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      state.expires = null;
      state.user_id = null;
      state.accessToken = null;
      state.refreshToken = null;
    },
  },
});

export const { setCredentials, logOut } = slice.actions;

export default slice.reducer;
