import { useSelector } from 'react-redux';
import { RootState } from '../../services/reduxStore';

function useIsLoggedIn() {
  const { accessToken, refreshToken } = useSelector(({ auth }: RootState) => auth);

  if (!accessToken || !refreshToken) {
    return false;
  } return true;
}

export default useIsLoggedIn;
